/* #### Generated By: http://www.cufonfonts.com #### */

$color-text: #ffffff;
$font-size-text: 1.8vw;
$font-size-menu: 0.8vw;

$font-size-footer: 0.8vw;
$font-size-footer-mail: 1.2vw;

$item-spacing: 1vw;

$frame-margin: 2vw;

$link-color: rgba(0,0,0,0.7);
$screen-padding: 2.5vw;

$mobile-breakpoint: 1024px;

h1 {
  font-family: 'Qanelas Bold';
  font-size: 1.5vw;
  margin:0;
  padding:0;
}
h2 {
  font-family: 'Qanelas Bold';
  font-size: 1.1vw;
  margin:0;
  padding:0;
}

@font-face {
  font-family: 'Qanelas Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Qanelas Regular'), url('./fonts/QanelasRegular.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Qanelas Regular Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Qanelas Regular Italic'), url('./fonts/QanelasRegularItalic.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Qanelas Thin';
  font-style: normal;
  font-weight: normal;
  src: local('Qanelas Thin'), url('./fonts/QanelasThin.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Qanelas UltraLight';
  font-style: normal;
  font-weight: normal;
  src: local('Qanelas UltraLight'), url('./fonts/QanelasUltraLight.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Qanelas Thin Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Qanelas Thin Italic'), url('./fonts/QanelasThinItalic.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Qanelas UltraLight Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Qanelas UltraLight Italic'), url('./fonts/QanelasUltraLightItalic.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Qanelas Light';
  font-style: normal;
  font-weight: normal;
  src: local('Qanelas Light'), url('./fonts/QanelasLight.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Qanelas Light Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Qanelas Light Italic'), url('./fonts/QanelasLightItalic.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Qanelas Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Qanelas Medium'), url('./fonts/QanelasMedium.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Qanelas Medium Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Qanelas Medium Italic'), url('./fonts/QanelasMediumItalic.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Qanelas SemiBold';
  font-style: normal;
  font-weight: normal;
  src: local('Qanelas SemiBold'), url('./fonts/QanelasSemiBold.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Qanelas SemiBold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Qanelas SemiBold Italic'), url('./fonts/QanelasSemiBoldItalic.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Qanelas Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Qanelas Bold'), url('./fonts/QanelasBold.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Qanelas Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Qanelas Bold Italic'), url('./fonts/QanelasBoldItalic.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Qanelas ExtraBold';
  font-style: normal;
  font-weight: normal;
  src: local('Qanelas ExtraBold'), url('./fonts/QanelasExtraBold.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Qanelas ExtraBold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Qanelas ExtraBold Italic'), url('./fonts/QanelasExtraBoldItalic.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Qanelas Black';
  font-style: normal;
  font-weight: normal;
  src: local('Qanelas Black'), url('./fonts/QanelasBlack.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Qanelas Heavy';
  font-style: normal;
  font-weight: normal;
  src: local('Qanelas Heavy'), url('./fonts/QanelasHeavy.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Qanelas Black Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Qanelas Black Italic'), url('./fonts/QanelasBlackItalic.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Qanelas Heavy Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Qanelas Heavy Italic'), url('./fonts/QanelasHeavyItalic.woff') format('woff');
  }

  body {
    font-family: 'Qanelas Light', sans-serif;
    color: $link-color;
    background: rgba(0,0,0,0.05);
    font-size: clamp(20px,1.2vw, 30px);
    @media only screen and (max-width: $mobile-breakpoint) {
      font-size: 5.2vw !important;
    }
  }

  @media only screen and (max-width: $mobile-breakpoint) {
    .header {
      justify-content:center;
      // top:10vh !important;
      padding-top:6vw !important;
      padding-left:5vw !important;
      padding-bottom:3vw !important;
    }
  }

  .header {
    position:fixed;
    top: 0;
    z-index:9999;
    background:rgba(244,244,244,0.8);
    left:0;
    right:0;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    padding: calc(0.3 * #{$screen-padding});
    padding-bottom:clamp(10px, calc(0.3 * #{$screen-padding}), 50px);
    padding-left:$screen-padding;
    padding-right:$screen-padding;
    align-items:center;
    z-index:1000;
    //background: #fff;

    .title {
      font-size: clamp(25px,1.5vw, 30px);
      @media only screen and (max-width: $mobile-breakpoint) {
        font-size: 6.5vw !important;
      }
      font-family: 'Qanelas Light';
    }

    .menu {
      padding-top:0.5vw;
      
      a {
        text-decoration:none;
        color: $link-color;
        padding:1vw;
        font-size: clamp(20px,1.0vw, 40px);
        @media only screen and (max-width: $mobile-breakpoint) {
          font-size: 6.5vw !important;
        }
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  .card {
    height:100vh;
    width:100vw;
    position:relative;
  }

  .App {
    position:relative;
  }

  .image-landing_4k {
    background-image: url('./images/landing_4k.jpg');
    background-repeat:no-repeat;
    background-position:bottom;
    background-size: cover;
    position:absolute;
    width:100%;
    height:100%;
    left:0;
    top:0;
  }

  .image-landing_tablet{
    background-image: url('./images/landing_hd.jpg');
    background-repeat:no-repeat;
    background-position:bottom;
    background-size: cover;
    width:100%;
    height:100%;
    left:0;
    top:0;
  }

  .image-landing_hd {
    background-image: url('./images/landing_tablet.jpg');
    background-repeat:no-repeat;
    background-position:bottom;
    background-size: cover;
    width:100%;
    height:100%;
    left:0;
    top:0;
  }

  .image-landing_mobile {
    background-image: url('./images/landing_mobile.jpg');
    background-repeat:no-repeat;
    background-position:bottom;
    width:100%;
    height:100%;
    left:0;
    top:0;
    background-size: cover;
  }

  .icon {
    margin: clamp(20px,1.2vw, 30px);
    width: clamp(20px,2.5vw, 30px);
    height: clamp(20px,2.5vw, 30px);
    @media only screen and (max-width: $mobile-breakpoint) {
      width: 5vw !important;
      height: 5vw !important;
      margin:5vw !important;
    }
    opacity: 0.7;
    &:hover {
      opacity:0.5;
    }
  }

  .socials {
    position:absolute;
    right: 0;
    top:0;
    bottom:0;
    z-index:9999;
    margin-right:2vw;
    display:flex;
    justify-content:center;
    flex-direction:column;
    align-items:center;
    position:fixed;
  }


  .image-facebook {
    background-image: url('./images/icons/facebook.svg');
    background-repeat:no-repeat;
    background-position:center;
    background-size: auto 100%;
  }

  .image-instagram {
    background-image: url('./images/icons/instagram.svg');
    background-repeat:no-repeat;
    background-position:center;
    background-size: auto 100%;
  }

  .image-spotify {
    background-image: url('./images/icons/spotify.svg');
    background-repeat:no-repeat;
    background-position:center;
    background-size: auto 100%;
  }

  .image-youtube {
    background-image: url('./images/icons/youtube.svg');
    background-repeat:no-repeat;
    background-position:center;
    background-size: auto 100%;
  }

  .image-illusions-cover_4k {
    background-image: url('./images/cover_4k.jpg');
    background-repeat:no-repeat;
    background-position:center;
    background-size: auto 100%;
    width: clamp(500px,30vw, 750px);
    height: clamp(500px,30vw, 750px);
    @media only screen and (max-width: $mobile-breakpoint) {
      margin-top:5vw!important;
      width:80vw!important;
      height:80vw!important;
    }
  }

  .image-illusions-cover_hd {
    background-image: url('./images/cover_hd.jpg');
    background-repeat:no-repeat;
    background-position:center;
    background-size: auto 100%;
    width: clamp(500px,30vw, 750px);
    height: clamp(500px,30vw, 750px);
    @media only screen and (max-width: $mobile-breakpoint) {
      margin-top:5vw!important;
      width:80vw!important;
      height:80vw!important;
    }
  }

  .image-illusions-cover_tablet {
    background-image: url('./images/cover_tablet.jpg');
    background-repeat:no-repeat;
    background-position:center;
    background-size: auto 100%;
    width: clamp(500px,30vw, 750px);
    height: clamp(500px,30vw, 750px);
    @media only screen and (max-width: $mobile-breakpoint) {
      margin-top:5vw!important;
      width:80vw!important;
      height:80vw!important;
    }
  }

  .image-illusions-cover_mobile {
    background-image: url('./images/cover_mobile.jpg');
    background-repeat:no-repeat;
    background-position:center;
    background-size: auto 100%;
    width: clamp(500px,30vw, 750px);
    height: clamp(500px,30vw, 750px);
    @media only screen and (max-width: $mobile-breakpoint) {
      margin-top:5vw !important;
      width:80vw!important;
      height:80vw!important;
    }
  }

  @media only screen and (max-width: $mobile-breakpoint) {
    .image-illusions-cover {
      width:80vw!important;
      height:80vw!important;
      margin-top:5vw!important;

    }
  }

  .container {
    display:flex;
  }

  .newsletter {
    background: rgba(0,0,0,0.05);
    display:flex;
    justify-content:center;
    flex-direction:column;
    height:50vh;
    .form {
      display:flex;
      justify-content:center;
      align-items:center;
      flex-direction:row;
      input {
        background:none;
        border: 0.12vw solid rgba(0,0,0,0.8);
        font-family: 'Qanelas Light';
        padding: 0.7vw;
        border-radius: 0.1vw;
        font-size: clamp(18px,0.8vw, 20px);
        @media only screen and (max-width: $mobile-breakpoint) {
          font-size: 5vw !important;
        }
      }
      .btn {
        background:none;
         border: 0.12vw solid rgba(0,0,0,0.8);
         margin-left: 0.5vw;
        font-family: 'Qanelas Bold';
        padding: 0.5vw;
        border-radius: 0.1vw;
        cursor:pointer;
        &:hover {
          background: rgba(0,0,0,0.8);
          color: rgba(255,255,255,0.8);
          transition: 0.2s;
        }
        
        //background: rgba(0,0,0,0.8);
      }
      .btn {
        background:none;
         border: 0.12vw solid rgba(0,0,0,0.8);
         margin-left: 0.5vw;
        font-family: 'Qanelas Bold';
        padding: 0.5vw;
        border-radius: 0.1vw;
        cursor:pointer;
        font-size: clamp(20px,1.1vw, 40px);
        @media only screen and (max-width: $mobile-breakpoint) {
          font-size: 5.2vw !important;
        }
        
        
        //background: rgba(0,0,0,0.8);
      }

    }

    p {
      text-align:center;
      font-size: clamp(20px,1.2vw, 40px);
      @media only screen and (max-width: $mobile-breakpoint) {
        font-size: 5vw !important;
      }
    }
  }
  .title {
    font-size: clamp(40px,2vw, 60px);
    @media only screen and (max-width: $mobile-breakpoint) {
      font-size: 8.5vw !important;
      padding-left: 10vw;
      padding-right: 10vw;
    }
    display:flex;
    justify-content:center;
  }
  .footer {
    
    strong {
      font-family: 'Qanelas Bold';
    }
    display:flex;
    flex-direction: column;
    
    height:auto;
    .newsletter {
      padding: $screen-padding;
      
    }
   
    .end {
      padding: $screen-padding;
      display:flex;
      justify-content: space-between;
      width: calc(100vw - 2 * #{$screen-padding});
      font-size: clamp(15px,0.8vw, 25px);
      @media only screen and (max-width: $mobile-breakpoint) {
        font-size: 4vw !important;
      }
      strong {
        font-family: 'Qanelas Light';
      }
    }
  }

  .person {
    padding-top:calc( 0.5 * #{$screen-padding});
    .position {
      font-size: clamp(15px,0.8vw,30px);
      @media only screen and (max-width: $mobile-breakpoint) {
        font-size: 4vw !important;
      }
      font-family: 'Qanelas SemiBold';
      text-transform: uppercase;
    }
  }

  .music {
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    height:auto;
    .title {
      margin-top: $screen-padding;
      margin-bottom: $screen-padding;
      text-transform: uppercase;
      strong {
        font-size: clamp(25px,1.2vw, 45px);
        @media only screen and (max-width: $mobile-breakpoint) {
          font-size: 7vw !important;
        }
        display:flex;
        justify-content:center;
        align-items:center;
        margin-left: 1vw;
        font-family: 'Qanelas Bold';
      }
    }
  }
/*
  .spotify {
    width: 100%;
    overflow:hidden;
    height:50vh;
    width: clamp(500px,30vw, 750px);
    height: clamp(500px,50vw, 750px);
  }
*/
  .music {
    margin-top:2.5vw;
    margin-bottom: 5vw;
  }

  .credits {
    margin-top:0vw;
    margin-bottom:3vw;
    .title {
      margin:0;
      justify-content:left;
      font-size: clamp(20px,1.1vw, 40px);
      @media only screen and (max-width: $mobile-breakpoint) {
        font-size: 6vw !important;
      }
      font-family: "Qanelas SemiBold";
    }
    .col {
      margin-right:1vw;
      margin-left: 1vw;
    }

    @media only screen and (max-width: $mobile-breakpoint) {
      .container {
        flex-direction:column !important;
        .col {
          justify-content:center;
          text-align:center;
          margin-top:8vw;
          .title {
            margin-bottom:5vw;
          }
        }
      }
      margin-bottom:8vw;
    }
  }

  .spotify {
    margin:0;
    margin-top:2vw;
    width: 10vw;
    width:30vw;
    height:30vw;
    width: clamp(350px,30vw, 750px);
    height: clamp(350px,50vw, 350px);
    overflow:hidden;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3vw;
    .title {
      margin:0;
      margin-bottom:2vw;
      justify-content:left;
      font-size: clamp(20px,1.1vw, 40px);
      @media only screen and (max-width: $mobile-breakpoint) {
        font-size: 5vw !important;
      }
      font-family: "Qanelas SemiBold";
      justify-content:center;
    }

    .tracks {
      height:100%;
      width:100%;
      div {
        height:100%;
        width:100%;
      }
    }
    
  }

  @media only screen and (max-width: $mobile-breakpoint) {
    .spotify {
      width: 80vw;
      height: 50vh;
      margin-bottom:10vw;
    }
  }

  .preview {
    width: clamp(500px,30vw, 750px);
  }

  @media only screen and (max-width: $mobile-breakpoint) {
    .preview {
      width: 80vw;
    }
  }

  .gallery {
    margin-top:2vw;
    overflow:scroll;

    width:clamp(400px, 40vw, 800px);
    @media only screen and (max-width: $mobile-breakpoint) {
      width:80vw !important;
    }
    
    display:flex;
    .thumbnail {
      margin-right: 1vw;
    }
    img{
      width: clamp(120px, 20vw, 200px);
      @media only screen and (max-width: $mobile-breakpoint) {
        //width:20vw !important;
        //height:auto;
      }
      height:auto;
    }
  }


  .btn-enroll {
    background:rgba(244,244,244,0.8);
     border: 0.12vw solid rgba(244,244,244,0.8);
     color: rgba(0,0,0,0.8);
     width:clamp(50px,10vw,100px) !important;
     margin-left: auto;
    font-family: 'Qanelas Bold';
    padding: 0.5vw;
    border-radius: 0.1vw;
    position:absolute;
    bottom:10vw;
    left: 50%;
    text-align:center;
    cursor:pointer;
}



.icon-mobmenu {
  background-image: url('./images/icons/mobmenu.svg');
  background-repeat:no-repeat;
  background-position:center;
  position:fixed;
  z-index:9999;
  right: $frame-margin;
  width: clamp(30px, 5vw, 30px);
  height: clamp(30px, 5vw, 30px);
  @media only screen and (max-width: $mobile-breakpoint) {
    width:7vw !important;
    height:8vw !important;
  }
  display:none;
  top: 5vw;
  right: 5vw;
}

@media only screen and (max-width: $mobile-breakpoint) {
  .icon-mobmenu {
    display:block;
  }
  .hidden {
    display:none;
  }
}


@media only screen and (max-width: $mobile-breakpoint) {
  .menu {
    position:fixed;
    top:0;
    right:0;
    bottom:0;
    left:0;
    width: 100%;
    padding:0 !important;
    flex-direction:column;
    justify-content:center;
    background: rgba(255,255,255,0.8);

    display:flex;
    a {
      font-family: 'Qanelas Light';
      font-size: 10vw;
      text-align:center;
      padding-top:4vw !important;
      padding-bottom:4vw !important;
      text-align:center !important;
      padding-left:0 !important;
    }
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .icon-mobmenu {
    display:block;
  }
  .hidden {
    display:none;
  }

  .header {
    justify-content: left !important;
  }
}


@media only screen and (max-width: $mobile-breakpoint) {
  .card {
    padding-top:8vw;
  }
  .socials {
    z-index:9999;
    height:7vh;
    position:fixed;
    bottom:0;
    left:0;
    right:0;
    top:93vh;
    background: rgba(244,244,244,0.8);
    justify-content:center;
    .icon {
      margin-left:5vw;
      margin-right:5vw;
    }
    flex-direction:row;
  }

  .title {
    flex-direction:column;
    text-align:center;
    justify-content:center;
    align-items:center;
  }

  .gallery {
    width:calc(100% - 10vw);
    padding-left:10vw;
    .thumbnail {
      img {
        // width:30vw;
        width:auto;
        height:20vh;
      }
    }
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .footer {
    .end {
      justify-content:center;
      align-items:center;
      flex-direction:column !important;
      margin-bottom:15vw;
      div {
        margin-top:1vw;
        margin-bottom:1vw;
      }
    }
    
  }
}

.thumbnail {
  cursor:pointer;
  &:hover {
    opacity:0.8;
  }
}
.gallery::-webkit-scrollbar { display: none !important; };
.gallery {
  scrollbar-width: none;
}



.preview-container {
  position:fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  background: rgba(244,244,244,0.8);
  z-index:9999;
  display:flex;
  justify-content:center;
 
  align-items:center;
  img {
    width:70%;
    height:auto;
    
  }
}


.icon-cancel {
  background-image: url('./images/icons/cancel.svg') !important;
  background-repeat:no-repeat !important;
  background-position:center !important;
  width:20px;
  height:20px;
  position:fixed;
  right: 5vw;
  top:5vw;
  cursor:pointer;
  &:hover {
    opacity: 0.8;
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  p {
    padding-left:10vw;
    padding-right:10vw;
    text-align:center;
  }
}

.recap {
  position:absolute;
  bottom:0;
  padding-top:0.5vw;
  width:100%;
  text-align:center;
  display:flex;
  justify-content:center;
  top:110%;
}

.show {
  display:flex;
}

.form {
  position:relative;
}


.btn {
  display:inline-block;
  background:none;
   border: 0.12vw solid rgba(0,0,0,0.8);
   margin-left: 0.5vw;
  font-family: 'Qanelas Bold';
  padding: 0.5vw;
  border-radius: 0.1vw;
  cursor:pointer;
  &:hover {
    background: rgba(0,0,0,0.8);
    color: rgba(255,255,255,0.8);
    transition: 0.2s;
  }
  
  //background: rgba(0,0,0,0.8);
}
.btn {
  max-width:70px;
  text-align:center;
  display:inline-block;
  background:none;
   border: 0.12vw solid rgba(0,0,0,0.8);
   margin-left: 0.5vw;
  font-family: 'Qanelas Bold';
  padding: 0.5vw;
  border-radius: 0.1vw;
  cursor:pointer;
  font-size: clamp(20px,1.1vw, 40px);
  @media only screen and (max-width: $mobile-breakpoint) {
    font-size: 5vw;
  }

  
  //background: rgba(0,0,0,0.8);
}


.alert {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
}


@media only screen and (max-width: $mobile-breakpoint) {
  .newsletter {
    .form {
      input {
        padding: 2vw;
      }
    }
  }

  .btn {
    padding: 1.6vw !important;
    padding-left: 2vw !important;
  }

}



.portfolio-navigation {
  
  color:$color-text;
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
  position:relative;
  z-index:9999;

  .title {
    font-size: clamp(10px , 0.8vw, 20px);
    font-family: 'Qanelas Bold';

  }
  .left {
    background-image: url('./images/icons/left_arrow2.svg');
    background-repeat:no-repeat;
    background-position:center;
    background-size: 80% auto;
    width: clamp(30px ,1.2vw, 30px);
    height: clamp(30px ,1.2vw, 30px);
    margin-top: clamp(20px ,1.2vw, 30px);
    margin-right:2.5vw;
    cursor:pointer;
    &:hover {
      opacity: 0.7;
    }
  }
  .right {
    background-image: url('./images/icons/right_arrow2.svg');
    background-repeat:no-repeat;
    background-position:center;
    background-size: 80% auto;
    width: clamp(30px ,1.2vw, 30px);
    height: clamp(30px ,1.2vw, 30px);
    margin-top: clamp(20px ,1.2vw, 30px);
    order:1;
    cursor:pointer;
    &:hover {
      opacity: 0.7;
    }
  }

  @media only screen and (max-width: $mobile-breakpoint) {
    display:none;
  
  }
}